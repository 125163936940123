import React from 'react'
import cn from 'classnames'
import s from './Image.module.scss'
import Image from 'next/image'
type IProps = {
  className?: string
  name?: string
  src: string
  alt?: string
  onClick?: () => void
  style?: React.CSSProperties
}
const MyImage = (props: IProps) => {
  const { className = ' ', src = '', alt = '', onClick = null, style = {} } = props
  return (
    <div
      data-aos="fade-up"
      className={cn(s.warpper, className)}
      onClick={() => {
        onClick && onClick()
      }}
      style={style}
    >
      <Image src={src} layout="fill" alt={alt} />
    </div>
  )
}

export default MyImage
