import React, { useState, useEffect, useMemo, useCallback } from 'react'
import cn from 'classnames'
import s from './Footer.module.scss'
import { useRouter } from 'next/router'
import useLocale from '@/hooks/useLocale'
import MyImage from '../Image'
import Email from '@/icons/footer/email.svg'
import Linkedin from '@/icons/footer/Linkedin.svg'
import Telegram from '@/icons/footer/Telegram.svg'
import Facebook from '@/icons/footer/facebook.svg'
import Twitter from '@/icons/footer/twitter.svg'
import Link from 'next/link'
import Image from 'next/image'

type Iprops = {
  type?: number | string //1代表是home页面样式
  grandRef: any
}
const Footer = (props: Iprops) => {
  const router = useRouter()
  const { type, grandRef } = props
  const { t } = useLocale()
  const [productsOpen, setProductsOpen] = useState(true)
  const [isFirstProducts, setIsFirstProducts] = useState(true)

  const [solutionsOpen, setSolutionsOpen] = useState(false)
  const [insightsOpen, setInsightsOpen] = useState(false)
  const [companyOpen, setCompanyOpen] = useState(false)
  const [customerOpen, setCustomerOpen] = useState(false)
  const [currentYear, setCurrentYear] = useState(2024)

  const onExpand = useCallback(
    (type: string) => {
      isFirstProducts && setIsFirstProducts(false)
      if (type == 'products') {
        setProductsOpen(!productsOpen)
      } else if (type == 'solutions') {
        setSolutionsOpen(!solutionsOpen)
      } else if (type == 'insights') {
        setInsightsOpen(!insightsOpen)
      } else if (type == 'company') {
        setCompanyOpen(!companyOpen)
      } else if (type == 'customer') {
        setCustomerOpen(!customerOpen)
      }
    },
    [isFirstProducts, productsOpen, solutionsOpen, insightsOpen, companyOpen, customerOpen]
  )

  useEffect(() => {
    setCurrentYear(new Date().getFullYear())
    // const script = document.createElement('script')
    // script.src = '/ze.js?key=1cc85b32-f91a-4dec-a061-beac208ffa03'
    // script.id = 'ze-snippet'
    // script.async = true
    // script.onload = () => {
    //   ;(window as any)?.zE && (window as any).zE('messenger', 'hide')
    //   ;(window as any)?.zE && (window as any).zE('messenger', 'close')
    //   // 这里可以执行依赖于第三方脚本的代码
    // }
    // // 将script标签添加到DOM中
    // document.body.appendChild(script)
    // // 清理函数，移除事件监听器和script标签
    // return () => {
    //   script.onload && script.removeEventListener('load', script.onload)
    //   document.body.removeChild(script)
    // }
  }, [])
  const productList = useMemo(() => {
    const a = {
      software: {
        title: t('Software-as-a-Service'),
        list: [
          {
            title: t('Digital Assets Exchange'),
            link: '/product/exchange'
          },
          {
            title: t('Decentralized Exchange'),
            link: '/product/dex'
          },
          {
            title: t('Liquidity-as-a-service'),
            link: '/product/liquidity'
          },
          {
            title: t('Wallet-as-a-service'),
            link: '/product/wallet'
          },
          {
            title: t('NFT Marketplace'),
            link: '/product/nft'
          },
          {
            title: t('Know-Your-Transaction KYT'),
            link: '/product/kyt'
          },
        ]
      },
      blockchain: {
        title: t('Blockchain for Financial Services'),
        list: [
          {
            title: t('Asset Tokenization'),
            link: '/solution/AssetTokenization'
          },
          {
            title: t('Gaming and Metaverse'),
            link: '/solution/GamingandMetaverse'
          },

          {
            title: t('Smart Web3 Banking'),
            link: '/solution/SmartWeb3Banking'
          }
        ]
      },
      asset: {
        title: t('Asset Custody'),
        list: [
          {
            title: t('ChainUp Custody'),
            link: '/product/custody'
          }
        ]
      },
      assetManage: {
        title: t('Asset Management'),
        list: [
          {
            title: t('ChainUp Investment'),
            link: '/product/investment'
          }
        ]
      },
      web3: {
        title: t('Web3 Infrastructure'),
        list: [
          {
            title: t('Crypto Mining'),
            link: '/product/crypto-mining'
          },
          {
            title: t('Staking'),
            link: 'https://cloud.chainup.com/'
          },
          {
            title: t('Blockchain API'),
            link: 'https://cloud.chainup.com/blockapi'
          },

        ]
      }
    }
    return a
  }, [t])

  const solutionList = useMemo(
    () => [
      {
        title: t('Central Banks'),
        link: '/solutions/central-banks'
      },
      {
        title: t('Commercial Banks'),
        link: '/solutions/commercial-banks'
      },
      {
        title: t('Retail Banks'),
        link: '/solutions/retail-banks'
      },
      {
        title: t('Investment Banks'),
        link: '/solutions/investment-banks'
      },
      {
        title: t('Digital Asset Exchanges'),
        link: '/solutions/digital-asset-exchanges'
      },
      {
        title: t('Asset Management Firms'),
        link: '/solutions/asset-management-firms'
      },
      {
        title: t('Payment Platforms'),
        link: '/solutions/payment-platforms'
      },
      {
        title: t('Hedge Funds'),
        link: '/solutions/hedge-funds'
      },
      {
        title: t('OTC/Investment Brokerage Platforms'),
        link: '/solutions/otc-investment-borakage-platforms'
      },
      {
        title: t('Venture Capital/Private Equity Firms'),
        link: '/solutions/venture-capital-private-equity-firms'
      }
    ],
    [t]
  )
  const insightList = useMemo(
    () => [
      {
        title: t('News'),
        link: '/news'
      },
      {
        title: t('Blog'),
        link: '/blog'
      },
      {
        title: t('Events'),
        link: '/events'
      },
      {
        title: t('Market Updates'),
        link: '/marketupdates'
      }
    ],
    [t]
  )
  const aboutUsList = useMemo(
    () => [
      {
        title: t('About'),
        link: '/about'
      },
      {
        title: t('Contact Us'),
        link: '/Contact_us'
      },
      {
        title: t('Leadership'),
        link: '/team'
      },
      {
        title: t('Careers'),
        link: '/job'
      }
    ],
    [t]
  )

  const socialItems = useMemo(
    () => [
      {
        link: 'mailto:info@chainup.com',
        icon: <Email />,
        label: 'footer mailto'
      },
      {
        link: 'https://www.linkedin.com/company/ChainUP-Technology/',
        icon: <Linkedin />,
        label: 'footer linkedin'
      },
      {
        link: 'https://www.facebook.com/ChainUPTechnology/',
        icon: <Facebook />,
        label: 'footer facebook'
      },
      {
        link: 'https://twitter.com/ChainUPOfficial',
        icon: <Twitter />,
        label: 'footer twitter'
      },
      {
        link: 'https://t.me/ChainUp2022',
        icon: <Telegram />,
        label: 'footer me'
      }
    ],
    []
  )

  return (
    <div className={cn(s.footerWarpper, { [s.footerWarpperHome]: type == 1 })} id="footer" ref={grandRef}>
      <div className={s.content}>
        <div className={cn(s.menuWarpper, s.socilaWarpper)}>
          <MyImage className={s.socilaImg} src="/images/home/header/logo_white.svg" data-aos="fade-up"></MyImage>
          <div className={s.certification_text} data-aos="fade-up">
            {t('Certifications')}
          </div>
          <div className={s.sco_img} data-aos="fade-up">
            <Link href="/news/ChainUp-Earns-Best-Institutional-Custody-Asset-Service-Recognition-2023">
              <a target={'_blank'}>
                <Image src="/images/common/footer/RAlogo.svg" layout="fill" alt=""></Image>
              </a>
            </Link>
          </div>
          <div className={cn(s.certificate_warpper, s.certificate_warpper1)} data-aos="fade-up">
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-now-SOC-2-Type-1-certified-Blockchain-Solutions-Provider">
                <a target={'_blank'}>
                  <Image src="/images/home/banner/ios_type1.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
            <div className={s.ios_img}>
              <Link href="/news/chainup-is-now-soc-2-type-2-certified">
                <a target={'_blank'}>
                  <Image src="/images/home/banner/ios_type2.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
          </div>
          <div className={cn(s.certificate_warpper, s.certificate_warpper2)} data-aos="fade-up">
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <a target={'_blank'}>
                  <Image src="/images/home/banner/iso1.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <a target={'_blank'}>
                  <Image src="/images/home/banner/iso2.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <a target={'_blank'}>
                  <Image src="/images/home/banner/iso3.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
          </div>

          <div className={s.link_warpper}>
            {socialItems.map((item, index) =>
              item.link == 'mailto:info@chainup.com' ? (
                <a
                  key={index}
                  data-aos="fade-up"
                  onClick={() => {
                    window.location.href = 'mailto:info@chainup.com'
                  }}
                >
                  {item.icon}
                </a>
              ) : (
                <Link key={index} href={item.link}>
                  <a data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.icon}
                  </a>
                </Link>
              )
            )}
          </div>
        </div>

        <div className={cn(s.solutionsWarpper)}>
          <div className={cn({ [s.openWarpper]: productsOpen })}>
            <div
              className={cn(s.menuTitle, s.solutionsTitle)}
              data-aos="fade-up"
              onClick={() => {
                onExpand('products')
              }}
            >
              <span>{t('Products')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={cn(s.solutions, s.itemOpen, { [s.itemOpen_first]: isFirstProducts })}>
              <div>
                <div className={s.itemTitle} data-aos="fade-up">
                  {productList.software.title}
                </div>
                {productList.software.list.map((item, index) => (
                  <Link key={index} href={item.link}>
                    <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                      {item.title}
                    </a>
                  </Link>
                ))}
              </div>
              <div className={s.itemTitle} data-aos="fade-up">
                {productList.blockchain.title}
              </div>

              {productList.blockchain.list.map((item, index) => (
                <Link key={index} href={item.link}>
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}

              <div className={s.itemTitle} data-aos="fade-up">
                {productList.asset.title}
              </div>

              {productList.asset.list.map((item, index) => (
                <Link key={index} href={item.link}>
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
              <div className={s.itemTitle} data-aos="fade-up">
                {productList.assetManage.title}
              </div>

              {productList.assetManage.list.map((item, index) => (
                <Link key={index} href={item.link}>
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}



              <div className={s.itemTitle} data-aos="fade-up">
                {productList.web3.title}
              </div>

              {productList.web3.list.map((item, index) => (
                <Link key={index} href={item.link}>
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={cn(s.solutionsWarpper, s.solutionsWarpperWidth)}>
          <div className={cn({ [s.openWarpper]: solutionsOpen })}>
            <div
              className={cn(s.menuTitle, s.solutionsTitle)}
              data-aos="fade-up"
              onClick={() => {
                onExpand('solutions')
              }}
            >
              <span>{t('Solutions')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {solutionList.map((item, index) => (
                <Link href={item.link} key={index}>
                  <a data-aos="fade-up" className={cn(s.solutionsItem)} target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={s.insightsWarpper}>
          <div className={cn({ [s.openWarpper]: insightsOpen })}>
            <div
              className={s.menuTitle}
              data-aos="fade-up"
              onClick={() => {
                onExpand('insights')
              }}
            >
              <span>{t('Insights')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {insightList.map((item, index) => (
                <a data-aos="fade-up" className={s.solutionsItem} target={item.link.indexOf('http') != -1 ? '_blank' : '_self'} key={index} onClick={() => {
                  if (router.locale == 'en' || (router.locale == 'zh' && item.link != '/events')) {
                    router.push(item.link)
                  } else {
                    window.location.href = '/' + 'en' + item.link
                  }
                }}>
                  {item.title}
                </a>
              ))}
            </div>
          </div>
          <div className={cn({ [s.openWarpper]: companyOpen })}>
            <div
              className={cn(s.menuTitle, s.companyTitle)}
              data-aos="fade-up"
              onClick={() => {
                onExpand('company')
              }}
            >
              <span>{t('Our Company')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {aboutUsList.map((item, index) => (
                <Link href={item.link} key={index}>
                  <a data-aos="fade-up" className={s.solutionsItem} target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
          <div className={cn(s.customerWarpper, { [s.openWarpper]: customerOpen }, s.customerWarpperPc)}>
            <div className={cn(s.menuTitle, s.companyTitle)} data-aos="fade-up">
              <span>{t('Customer Support')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {/* <div
                id="conversation-badge"
                className={s.solutionsItem}
                data-aos="fade-up"
                onClick={() => {
                  ;(window as any)?.zE && (window as any).zE('messenger', 'show')
                  ;(window as any)?.zE && (window as any).zE('messenger', 'open')
                }}
              >
                {t('Support Chat')}
              </div> */}
              <Link href="/service-terms">
                <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                  {t('Support Chat')}
                </a>
              </Link>
              <Link href="/service-terms">
                <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                  {t('Service Terms')}
                </a>
              </Link>
            </div>
          </div>
        </div>

        <div className={cn(s.customerWarpper, { [s.openWarpper]: customerOpen }, s.customerWarpperh5)}>
          <div
            className={cn(s.menuTitle, s.companyTitle)}
            data-aos="fade-up"
            onClick={() => {
              onExpand('customer')
            }}
          >
            <span>{t('Customer Support')}</span>
            <div className={s.isOpen}></div>
          </div>
          <div className={s.itemOpen}>
            {/* <div
              id="conversation-badge"
              className={s.solutionsItem}
              data-aos="fade-up"
              onClick={() => {
                ;(window as any)?.zE && (window as any).zE('messenger', 'show')
                ;(window as any)?.zE && (window as any).zE('messenger', 'open')
              }}
            >
              {t('Support Chat')}
            </div> */}
            <Link href="/service-terms">
              <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                {t('Support Chat')}
              </a>
            </Link>
            <Link href="/service-terms">
              <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                {t('Service Terms')}
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className={s.socilaFooter} data-aos="fade-up">
        {t('Copyright © {{currentYear}} ChainUp. All rights reserved', { currentYear })}
        <Link href="/privacyPolicy">
          <a>&nbsp;&nbsp;{t('Privacy Policy')}&nbsp;&nbsp;</a>
        </Link>
        <Link href="/terms-of-service">
          <a>{t('Terms of Service')}</a>
        </Link>
      </div>
    </div>
  )
}

export default Footer
